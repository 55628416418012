export const environment = {
    RECAPTCHA_PUBLIC: '6Lf4GxkqAAAAAGyR-CR0Igj6rF1R4x5CsTODTaQY',

    production: false,
    PROXY: false,
    passwordDevQa: 'sv2024',
    API_URL_V1: 'https://api-dev.santovecino.com/api/v1/',
    API_URL_V2: 'https://api-dev.santovecino.com/api/v2/',

    KEY_CRYPTO: 'W730Mui+POeHM+WuN+Qe[FhKp]QViY6M',
    URL_FILE: 'https://santo-vecino-dev.s3.amazonaws.com/',
    CATEGORY_FILE: 'https://santovecino.s3.amazonaws.com/',
    URL_SANTO_VECINO: 'https://dev.santovecino.com',
    VAPID_PUBLIC_KEY:
        'BHGzXf74WGndRE9FV5qmaTrjLNyKj9Rhc5k47ijLp0SjQLpzRAlTPzt3_7UP9InDpeji5ryJ0dDMxN2EIE7jU14',
    VAPID_PRIVATE_KEY: 'G6pT9nqhvTqSGH_JwTV62znG9YVlNyCG5LoJgn_ENtA',
    ALGOLIA: {
        appId: 'MXVYLR2PDO',
        apiKey: '560c76fc21851b9827288a6e72a99a4c',
        indexName: 'dev-santovecino',
        indexNameCategory: 'dev-category',
        urlSync: false,
    },

    GOOGLE_CLIENT_ID:
        '752925264395-6ns291urcn77a3hbib3tt0e1rhfgm3je.apps.googleusercontent.com',
    GOOGLE_SECRET: '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',
    GOOGLE_CALL_BACK_URL:
        'https://api-dev.santovecino.com/api/auth/google/redirect',
};

export const googleTagManager = {
    id: 'GTM-W5K2V6PN',
};

export const environmentVideo = {
    PRE_HOME:
        'https://santovecino.s3.amazonaws.com/pre-home/video/video-v4/prehome.m3u8',
    PRE_TUTORIAL:
        'https://santovecino.s3.amazonaws.com/helps-videos/register/v1/register.m3u8',
    HOW_TO_PUBLISH:
        'https://santovecino.s3.amazonaws.com/helps-videos/how-to-publish/v1/publication.m3u8',
};
